.redButton {
    background-color: rgb(66, 0, 0);
    border: none; 
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	transition: background-color 0.3s ease-out;
}

.redButton:hover {
    background-color: rgb(98, 4, 4);
}

.greenButton {
    background-color: rgb(59, 196, 32) !important;
    border: none; 
	color: white;
	padding: 15px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 10px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	transition: background-color 0.3s ease-out;
}

.greenButton:hover {
    background-color: rgb(31, 136, 39) !important;
}