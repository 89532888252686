.app-page {
  background: rgb(222,7,7);
  background: radial-gradient(circle, rgba(222,7,7,1) 0%, rgba(0,0,0,1) 100%);
  background-repeat: repeat !important;
  margin-top: 0% !important;
  text-align: center;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-weight: bold !important;
  font-size: large;
}

.page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin: auto;
  text-align: center;
  color: white;
  padding-top: 9%;
}

.logo {
  height: 30vh;
  max-width: 100vw;
}

.input-field {
  width: 30%;
  padding: 1.5vh 2vh;
  margin: 2vh 0;
  display: inline-block;
  background-color:  white;
  border: 3px solid #ff0000;
  border-radius: 16px;
  box-sizing: border-box;
}

@media only screen and (max-width: 768px) {
  .page {
    padding-top: 35%;
  }
  .logo {
    width: 80vw;
    height: auto;
  }
  .input-field {
    width: 65%;
    border: 2px solid #ff0000;
  }
}