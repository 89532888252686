.swim-image-small {
    width: 100px; /* Ajustează lățimea imaginii */
    height: 100px; /* Asigură proporții corecte */
  }
.fade-in {
opacity: 0;
transition: opacity 1s ease-in-out;
}

.fade-in.show {
opacity: 1;
}

.edit-btn{
  background-color: transparent;
  border: none;
}

.edit-icon {
    width: 20px;
    margin-left: 5px;
    cursor: pointer;
}

/* pt linia de plata */
.payment-methods {
  display: flex;
  justify-content: space-between;
  padding: 10px 0; /* Adds some space above and below the line */
}

.payment-methods span {
  flex: 1;
  text-align: center;
  font-size: 25px; /* Increase the font size */
}

/* Color the first span green */
.payment-methods span:nth-child(1) {
  color: green;
}

/* Color the second span blue */
.payment-methods span:nth-child(2) {
  color: aqua;
}

/* Color the third span a darker yellow */
.payment-methods span:nth-child(3) {
  color: #DAA520; /* GoldenRod is a darker yellow */
}

/* Optional: Adjust font size on smaller screens if needed */
@media (max-width: 600px) {
  .payment-methods span {
      font-size: 20px; /* Slightly smaller font size on mobile */
  }
}


/* pana aici linie plata */