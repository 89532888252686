.red-pulse {
    color: red;
    animation: pulse 1s infinite;
  }

  .red-custom {
    color: red;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .yellow-pulse {
    color: rgb(200, 255, 0);
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }