.logo-search {
    width: 80vw;
    height:auto;
}

.child-container {
    position: relative;
    height: 14vh;
    width: 25vh;
    background-color: transparent;
}

.pulsating-bordergrench {
    animation: pulse 2s infinite;
}

.pulsating-borderzero {
    animation: pulse 1s infinite;
}

.border-dottedP {
    border-style: dashed !important;
  }
  
.border-HalfStyle {
    border-style: dashed solid dashed solid !important;
    /*solid dashed solid dashed 
    solid solid dashed dashed
    dashed dashed solid solid
    dashed solid dashed solid*/
    border-color:  red  green red green !important;
    animation: pulse 2s infinite;
  } /* Pentru a face border-ul jumate punctat jumate solid */
  
  @keyframes pulseAnimation {
    0%, 100% {
      border-color: red;
    }
    50% {
      border-color: white;
    }
  }
  
  .pulsating-border {
    animation: pulseAnimation 2s infinite;
  }
  
  .pulsating-border-alabala {
    animation: pulseAnimation 2s infinite;
  }

  
.check-attendance {
    position: absolute;
    top: 10%;
    right: 5%;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}
  

.search-bar {
    width: 90%;
    padding: 1.5vh 2vh;
    margin: 20px 0;
    display: inline-block;
    background-color:  white;
    border: 3px solid #ff0000;
    border-radius: 16px;
    box-sizing: border-box;
}

.no-padding-top {
    padding-top: 0% !important;
}

.attendance {
    position: relative;
    width: 25vh;
    height: 25vh;
}

@media only screen and (min-width: 768px) {
    .logo-search {
        height: 10vh;
        width: auto;
    }
}

/*border juma rosu juma verde re-0sed*/
.pulsating-border-JumiZero {
    animation: pulse 2s infinite;
    border-image: linear-gradient(to right, green 50%, red 50%) !important;
    border-image-slice: 1 !important;
    /* make the border rounded */

}

/*border juma rosu juma verde  re-Ultsed*/
.pulsating-border-JumiUlt {
    animation: pulse 2s infinite;
    border-image: linear-gradient(to right, green 50%, yellow 50%) !important;
    border-image-slice: 1 !important;
}

.rounded-button {
    border-radius: 20px; /* Adjust the radius value as needed */
}


.warning-icon {
    font-size: 2rem;
    width: 13%;
}

@keyframes pulse-warning {
    0% {
        transform: scale(0.85);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(0.85);
    }
}

.pulsating-warning {
    animation: pulse-warning 2s infinite;
}