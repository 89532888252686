.dropdown-container {
  position: relative;
  width: 200px;
}
  
.dropdown-select {
  appearance: none;
  width: 100%;
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #555;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
  
.dropdown-select:hover,
.dropdown-select:focus {
  border-color: #aaa;
}
  
.dropdown-select::placeholder {
  color: #999;
}
  
.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
}
  
.dropdown-arrow img {
  width: 100%;
  height: 100%;
}
  
.dropdown-options {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
  
.dropdown-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
  
.dropdown-option:hover {
  background-color: #f5f5f5;
}

.dropdown-option.selected {
  background-color: #e1e1e1;
}
  