.white-header {
    background-color: #de0707;
    border: none; 
    color: #000000;
    padding: 10px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-datepicker__day--outside-month {
	color: transparent !important;
	pointer-events: none;
}

.small-rank {
	width: 5vh;
	height: 5vh;
}

.medium-rank {
    width: 7.5vh;
    max-width: 12.5vw;
    height: auto;
}

.gift-given {
    width: 3vh;
    max-width: 10vw;
    height: auto;
}

.large-rank {
	max-width: 100%;
    width: 400px;
	height: auto;
}

.rank-label {
    position: absolute;
    transform: translate(-50%, 0);
    top: 100%;
    left: 50%;
    width: 10vw;
    font-size: large !important;
    font-weight: bold;
    font-style: italic;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

.selected-progress {
    border-width: 0.5vh;
    border-style: solid;
    border-image: linear-gradient(to bottom, rgba(255,255,255,0) 60%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 100%);
    border-image-slice: 1;
}

.non-selected-progress {
    border-width: 0.5vh;
    border-style: solid;
    border-color: transparent;
}

.progress-label {
    position: absolute;
    font-size: x-small;
    color: white;
    font-weight: bold;
    font-style: italic;
    color: black;
    left: 50%;
    transform: translate(-50%, 0);
}

.progress-container {
    position: relative;
    width: 10vw;
    height: 1.5vw;
    background: rgb(224, 224, 222); 
    border-radius: 50px;
    margin: auto;
    overflow: hidden;
}

.progress-container-main {
    position: relative;
    width: 100%;
    height: 3vh; 
    background: rgb(224, 224, 222); 
    border-radius: 50px;
    margin: auto;
    overflow: hidden;
}
.progress-label-main {
    position: absolute;
    font-size: small;
    color: white;
    font-weight: bold;
    font-style: italic;
    color: black;
    left: 50%;
    transform: translate(-50%, 0);
}

.scrollable {
    overflow: auto;
}

.star-attendance {
    position: absolute;
    height: 15%;
    top: 4%;
    right: 3%;
}

.pagination {
    --bs-pagination-color: red !important;
    --bs-pagination-active-color: white !important;
    --bs-pagination-active-bg: red !important;
    --bs-pagination-focus-color: red !important;
    --bs-pagination-hover-color: red !important;
    --bs-pagination-active-border-color: red !important;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(253, 13, 13, 0.25) !important;
}

@media only screen and (min-width: 992px) {
    .medium-rank {
        max-width: 4vw;
    }

    .progress-label {
        font-size: small;
    }

    .progress-label-main {
        font-size: medium;
    }
}

@media only screen and (max-width: 992px) {
    .rank-label {
        width: 15vw;
        font-size: small !important;
    }

    .progress-container {
        width: 22.5vw;
        height: 3.5vw;
    }
}

@media only screen and (max-width: 550px) {
    .rank-label {
        width: 20vw;
        font-size: small !important;
    }
}

.dropshadow-textgreen {
    color: green;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  /* styles.css */
/* styles.css */
.dropshadow-textred {
    color: red; /* Setați culoarea textului la roșu */
    -webkit-text-stroke: 0.3px white; /* Adăugați un contur alb */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Adăugați umbra textului */
  }
  

.dropshadow-textyellow {
color: rgb(208, 221, 26);
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.dropshadow-text {
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.7);
}
  
