@import "animate.css";

.attendee-form {
	width: 90vw;
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #2e0000 !important;
	background-color: #e9ecef;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
	color: #2e0000;
	background-color: #fff;
	border-color: rgb(98, 4, 4);
	outline: 0;
	box-shadow: 0 0 0 .25rem rgb(134, 5, 5) !important;
}

.form-label {
	margin-bottom: 0;
	color: rgb(98, 4, 4);
}

.invalid-feedback {
	display: block !important;
	width: 100%;
	margin-top: .25rem;
	font-size: .875rem;
	color: #fff !important;
	outline: solid 3px red;
	filter: drop-shadow(0 0 0.75rem red);
}

.react-datepicker-wrapper {
	display: block !important;
	width: 100%;
}

.my-custom-button {
	border: 2px solid rgb(0, 255, 51) !important; /* Schimbați culoarea roșie cu culoarea dorită */
	/* Alte stiluri dorite pentru butonul dvs. */
  }
