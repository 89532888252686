.signature {
    width: 90vw;
    height: 20vh;
    max-width: 500px;
    max-height: 300px;
    background-color: white;
}

.logo-circle {
    display: block;
    margin: 0 auto;
    max-width: 10%;
}
.logo-circle:hover {
    transform: rotate(360deg);
    transition: transform 0.6s ease-in-out;
  }

.small-font {
    font-size: medium !important;
}

.react-datepicker__day--outside-month {
	color: transparent !important;
	pointer-events: none;
  }
  
  
