.reschedule{
    .title{
        color:#02fc74;
        font-size: 14px;
        font-weight: bold;
    }

    .status{
        color: green;
        font-size: 12px;
    }

    .text-prezent {
        color: #00fd2d;
    }

    .text-absent {
        color: #fe0700;
    }

}